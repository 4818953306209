<template>
  <div class="flex flex-col items-center w-full mx-auto px-4 sm:px-8 md:px-16 lg:px-24 h-fit">
    <h1 class="text-2xl sm:text-3xl md:text-4xl font-black text-center mb-4 sm:mb-6 md:mb-8">
      Investor Search
    </h1>

    <div
      v-if="step === 1"
      class="flex flex-col w-full max-w-xl sm:max-w-2xl lg:max-w-5xl px-4 sm:px-8 py-8 sm:py-12 border rounded-lg m-2 sm:m-4"
    >
      <InvestorSearchHorizontalStepper
        class="mb-6 sm:mb-12"
        :step="step"
        @select="(s) => (step = s)"
      />

      <div class="text-left flex flex-col mb-6 sm:mb-12">
        <label class="font-bold mb-1">Find Company</label>
        <p class="text-xs mb-2">
          The company you select will be used to retrieve Industry Tags to focus on.
        </p>
        <VueMultiselect
          v-model="selectedCompany"
          searchable
          closeOnSelect
          label="name"
          trackBy="id"
          placeholder="Type to search"
          :options="companies"
          :loading="companySearchLoading"
          :showLabels="false"
          :internalSearch="false"
          :clearOnSelect="false"
          :allowEmpty="false"
          :showNoOptions="false"
          :optionsLimit="25"
          @select="(company) => (selectedCompany = company)"
          @searchChange="handleSearchCompanies($event, true)"
        >
          <template #noResult>
            <span>Oops! No companies found.</span>
          </template>
        </VueMultiselect>
      </div>

      <div v-if="selectedCompany" class="mb-8 sm:mb-16">
        {{ selectedCompany.description }}
      </div>

      <div class="flex justify-between">
        <button class="text-blue-600 text-xs" @click="handleSkipCompanySelection()">
          Skip to Tag Selection
        </button>
        <button
          @click="handleCompanySelectedNext()"
          :disabled="!selectedCompany"
          class="bg-white text-black font-semibold py-2 sm:py-3 px-6 sm:px-8 rounded border-gray drop-shadow-sm border"
          :class="{
            'cursor-pointer': selectedCompany,
            'cursor-not-allowed': !selectedCompany,
          }"
        >
          Next
        </button>
      </div>
    </div>

    <div
      v-if="step === 2"
      class="flex flex-col w-full max-w-xl sm:max-w-2xl lg:max-w-5xl px-4 sm:px-8 py-8 sm:py-12 border rounded-lg m-2 sm:m-4"
    >
      <InvestorSearchHorizontalStepper
        class="mb-6 sm:mb-12"
        :step="step"
        @select="(s) => (step = s)"
      />

      <div class="text-left flex flex-col mb-6 sm:mb-12">
        <label class="font-bold mb-1">Select Industry Tags</label>
        <p class="text-xs mb-2">The tags you select will be used to narrow in on investors.</p>
        <VueMultiselect
          v-model="selectedTags"
          :options="allTags.list"
          multiple
          label="name"
          trackBy="id"
          placeholder="Select industries"
          :showLabels="false"
          :allowEmpty="false"
          :optionsLimit="999"
          class="w-full"
        />
      </div>

      <div class="text-left flex flex-col mb-6 sm:mb-12">
        <label class="font-bold mb-1">Company deals to exclude</label>
        <p class="text-xs mb-2">
          Companies whose investors and deals you'd like excluded from results. For example,
          competitors, or the company itself if you wish to exclude existing investors.
        </p>
        <VueMultiselect
          v-model="excludedCompanies"
          searchable
          :closeOnSelect="false"
          :options="companies"
          multiple
          label="name"
          trackBy="id"
          placeholder="Select companies to exclude"
          @input="handleExcludedCompaniesChange"
          :loading="companySearchLoading"
          :optionsLimit="25"
          @searchChange="handleSearchCompanies($event, false)"
        />
      </div>

      <div v-if="selectedTags.length > MAX_NUM_TAGS" class="mb-8 sm:mb-16 text-red-400">
        Please limit your search to {{ MAX_NUM_TAGS }} industry tags.
      </div>

      <div class="flex justify-end">
        <button
          @click="handleTagSelection()"
          :disabled="selectedTags.length > MAX_NUM_TAGS || !selectedTags.length"
          class="bg-white text-black font-semibold py-2 sm:py-3 px-6 sm:px-8 rounded border-gray drop-shadow-sm border"
          :class="{
            'cursor-pointer': selectedTags.length <= MAX_NUM_TAGS && selectedTags.length,
            'cursor-not-allowed': selectedTags.length > MAX_NUM_TAGS || !selectedTags.length,
          }"
        >
          Next
        </button>
      </div>
    </div>

    <div
      v-if="step === 3 && asyncResultsGeneration"
      class="flex flex-col w-full max-w-xl sm:max-w-2xl lg:max-w-5xl px-4 sm:px-8 py-8 sm:py-12 rounded-lg m-2 sm:m-4 h-full border"
    >
      <p class="text-4xl sm:text-5xl md:text-6xl text-center mb-4 sm:mb-6">⏳ 📧</p>
      <p class="text-center mb-6 sm:mb-12">
        Due to the computations involved, we will email you soon notifying that the results are
        ready.
      </p>
      <p class="text-center">
        <button
          class="bg-white text-black font-semibold py-2 sm:py-3 px-4 sm:px-6 rounded border-gray drop-shadow-lg border"
          @click.prevent="handleNewSearch()"
        >
          New Search
        </button>
      </p>
    </div>

    <div
      v-else-if="step === 3"
      class="flex flex-col w-full max-w-xl sm:max-w-2xl lg:max-w-5xl px-4 sm:px-8 py-8 sm:py-12 rounded-lg m-2 sm:m-4 h-full border"
    >
      <InvestorSearchHorizontalStepper
        class="mb-6 sm:mb-12"
        :step="step"
        @select="(s) => (step = s)"
      />
      <label class="font-bold mb-6">
        Investor Filters
        <Popper arrow placement="right-end">
          <InformationCircleIcon class="h-5 w-5 cursor-pointer tooltip-investor-filters" />
          <template #content>
            <div class="bg-white rounded-lg p-2 border font-normal max-w-sm">
              <p class="mb-4">
                Use filters to include/exclude investors based on various criteria. Note that
                filtering is on the investor level, and not the deal / investment level.
              </p>
              <p>
                Activate desired filters and click
                <button
                  class="px-2 py-2 border bg-blue-100 border-blue-300 rounded drop-shadow-sm mx-1"
                >
                  <ArrowPathIcon class="h-3 w-3" />
                </button>
              </p>
            </div>
          </template>
        </Popper>
      </label>
      <div class="mb-6 sm:mb-12 flex flex-wrap items-center justify-between gap-2">
        <button
          class="text-sm font-semibold py-2 sm:py-3 px-4 sm:px-6 rounded border-gray drop-shadow-sm border cursor-pointer investor-midas-filter"
          :class="{
            'bg-white text-black': !midasFilter,
            'bg-black text-white': midasFilter,
          }"
          @click="midasFilter = !midasFilter"
        >
          Midas List
        </button>
        <div class="inline-flex rounded shadow-sm" role="group">
          <button
            type="button"
            class="text-sm font-semibold py-2 sm:py-3 px-4 sm:px-6 border border-gray-200 rounded-l-lg cursor-pointer investor-seed-stage-filter"
            :class="{
              'bg-white text-black': !seedStageFilter,
              'bg-black text-white': seedStageFilter,
            }"
            @click="seedStageFilter = !seedStageFilter"
          >
            Invested in Seed Stage
          </button>
          <button
            type="button"
            class="text-sm font-semibold py-2 sm:py-3 px-4 sm:px-6 border-t border-b border-gray-200 cursor-pointer investor-early-stage-filter"
            :class="{
              'bg-white text-black': !earlyStageFilter,
              'bg-black text-white': earlyStageFilter,
            }"
            @click="earlyStageFilter = !earlyStageFilter"
          >
            Invested in Early Stage
          </button>
          <button
            type="button"
            class="text-sm font-semibold py-2 sm:py-3 px-4 sm:px-6 border border-gray-200 rounded-r-lg cursor-pointer investor-late-stage-filter"
            :class="{
              'bg-white text-black': !lateStageFilter,
              'bg-black text-white': lateStageFilter,
            }"
            @click="lateStageFilter = !lateStageFilter"
          >
            Invested in Late Stage
          </button>
        </div>
        <button
          class="text-sm font-semibold py-2 sm:py-3 px-4 sm:px-6 rounded border-gray drop-shadow-sm border cursor-pointer investor-leads-rounds-filter"
          :class="{
            'bg-white text-black': !leadsRoundsFilter,
            'bg-black text-white': leadsRoundsFilter,
          }"
          @click="leadsRoundsFilter = !leadsRoundsFilter"
        >
          Has Lead Rounds
        </button>
        <button
          class="text-sm font-semibold py-2 sm:py-3 px-4 sm:px-6 rounded border-gray drop-shadow-sm border cursor-pointer investor-coinvestor-filter"
          :class="{
            'bg-white text-black': !coinvestsWithFrcFilter,
            'bg-black text-white': coinvestsWithFrcFilter,
          }"
          @click="coinvestsWithFrcFilter = !coinvestsWithFrcFilter"
        >
          Is FRC Co-investor
        </button>
        <button
          class="px-3 py-3 border bg-blue-100 border-blue-300 rounded drop-shadow-sm investor-search-results-button"
          @click="
            () => {
              trackFilters()
              handleGenerateResults()
            }
          "
        >
          <ArrowPathIcon class="h-5 w-5 cursor-pointer" />
        </button>
      </div>
      <label class="flex mb-2 sm:mb-4">
        <span class="font-bold">Results</span>
        <button
          class="text-green-600 ml-auto text-xs sm:text-sm"
          @click.prevent="handleGenerateResults(true)"
        >
          {{ exportButton }}
        </button>
      </label>
      <div class="w-full h-full relative">
        <InvestorSearchResultsTable
          :results="results"
          :industries="selectedTags"
          :key="results.map((result) => result.id).join('-') + lastFetchedResults"
          @show-investors="handleShowDealInvestors"
          @show-gps="handleShowGPs"
          @select-rows="(ids) => (selectedInvestorIds = ids)"
        />
      </div>
      <div class="flex justify-center mt-4">
        <span class="mr-2">Results:</span>
        <template v-for="(option, index) in MAX_NUM_RESULTS_OPTIONS" :key="option">
          <span
            :class="{
              'font-bold cursor-not-allowed': option === maxNumResults,
              'text-blue-600 dark:text-blue-500 hover:underline cursor-pointer':
                option !== maxNumResults,
            }"
            @click.prevent="
              () => {
                if (option === maxNumResults) return
                maxNumResults = option
                handleGenerateResults()
              }
            "
          >
            {{ option }}
          </span>
          <span v-if="index + 1 < MAX_NUM_RESULTS_OPTIONS.length" class="mx-1">|</span>
        </template>
      </div>
    </div>

    <InvestmentsModal
      v-if="isInvestmentsModalVisible"
      :deal-investors="dealInvestors"
      :has-multiple-industries="hasMultipleIndustries"
      :industry-name="industryName"
      @close="isInvestmentsModalVisible = false"
    />

    <GPsModal
      v-if="isGPsModalVisible"
      :deal-investors="dealInvestors"
      @close="isGPsModalVisible = false"
    />
  </div>
</template>

<script>
import debounce from 'lodash.debounce'
import { objectToCamelCase } from '@thinknimble/tn-utils'

import Company from '@/services/companies'
import CompanyIndustry from '@/services/companyIndustries'
import Industry from '@/services/industries'
import DealInvestor from '@/services/dealInvestors'

import { ArrowPathIcon, InformationCircleIcon } from '@heroicons/vue/24/solid'
import VueMultiselect from 'vue-multiselect'
import InvestorSearchHorizontalStepper from '@/components/InvestorSearch/InvestorSearchHorizontalStepper'
import InvestorSearchResultsTable from '@/components/InvestorSearch/InvestorSearchResultsTable'
import InvestmentsModal from '@/components/InvestorSearch/InvestmentsModal'
import TrackedEvent, { events } from '@/services/trackedEvents'
import GPsModal from '@/components/InvestorSearch/GPsModal'
import { trackHeapEvent } from '@/services/metrics'

const sortTagsByName = (a, b) => {
  const valueA = a.name.toLowerCase()
  const valueB = b.name.toLowerCase()

  if (valueA < valueB) return -1
  if (valueA > valueB) return 1
  return 0
}

const MAX_NUM_RESULTS_OPTIONS = [50, 75, 100]

export default {
  name: 'InvestorSearch',
  components: {
    ArrowPathIcon,
    InformationCircleIcon,
    VueMultiselect,
    InvestorSearchHorizontalStepper,
    InvestorSearchResultsTable,
    InvestmentsModal,
    GPsModal,
  },
  data() {
    return {
      // meta
      step: 1,
      MAX_NUM_TAGS: 5,
      MAX_NUM_RESULTS_OPTIONS,
      maxNumResults: MAX_NUM_RESULTS_OPTIONS.includes(parseInt(this.$route.query.pageSize))
        ? parseInt(this.$route.query.pageSize)
        : MAX_NUM_RESULTS_OPTIONS[0],
      results: [],
      asyncResultsGeneration: false,
      // filters
      midasFilter: !!this.$route.query.midas,
      leadsRoundsFilter: !!this.$route.query.leads,
      coinvestsWithFrcFilter: !!this.$route.query.coinvestor,
      seedStageFilter: !!this.$route.query.seed,
      earlyStageFilter: !!this.$route.query.early,
      lateStageFilter: !!this.$route.query.late,
      selectedCompany: null,
      companySearchLoading: false,
      companies: [],
      excludedCompanies: [],
      allTags: Industry.createCollection({
        pagination: {
          size: 999,
          page: 1,
        },
      }),
      selectedTags: [],
      isInvestmentsModalVisible: false,
      isGPsModalVisible: false,
      dealInvestors: [],
      selectedInvestorIds: [],
      lastFetchedResults: null,
    }
  },
  async created() {
    await this.allTags.refresh()
    if (this.$route.query.industries?.length) {
      this.$store.dispatch('setLoading', true)
      const preselectedTags = this.allTags.list.filter((t) =>
        this.$route.query.industries.includes(t.id),
      )
      preselectedTags.sort(sortTagsByName)
      this.selectedTags = preselectedTags
      this.handleGenerateResults()
    }
    if (this.selectedTags.length) {
      TrackedEvent.api.create(this.$store.getters.user.id, events.INVESTOR_SEARCH_PAGEVIEW, {
        industry_names: this.selectedTags.map((t) => t.name).join(','),
        industry_ids: this.selectedTags.map((t) => t.id).join(','),
        excluded_invested_in_company_ids: this.excludedCompanies.map((t) => t.id).join(','),
      })
    } else {
      TrackedEvent.api.create(this.$store.getters.user.id, events.INVESTOR_SEARCH_PAGEVIEW)
    }
  },
  watch: {
    excludedCompanies: {
      handler(newVal) {
        this.handleExcludedCompaniesChange()
      },
      deep: true,
    },
  },
  methods: {
    handleSearchCompanies(query, isPortfolioSearch) {
      if (!query) return
      this.companySearchLoading = true
      this.debouncedSearchCompanies(query, isPortfolioSearch)
    },
    // NOTE: internal, see computed.debouncedSearchCompanies
    _searchCompanies(query, portfolioOnly) {
      this.companySearchLoading = true

      // Create the filters object and conditionally add recordTypeId
      const filters = {
        ordering: 'name',
        name: query,
        hasCompanyIndustry: true,
      }

      // Add recordTypeId only if portfolioOnly is true
      if (portfolioOnly) {
        filters.hasAnyOfRecordTypeIds = process.env.VUE_APP_PORTFOLIO_COMPANY_RECORD_TYPE_ID
      } else {
        filters.hasAnyOfRecordTypeIds = [
          process.env.VUE_APP_PORTFOLIO_COMPANY_RECORD_TYPE_ID,
          process.env.VUE_APP_DEFAULT_COMPANY_RECORD_TYPE_ID,
          process.env.VUE_APP_PROSPECTIVE_COMPANY_RECORD_TYPE_ID,
        ].join(',')
      }

      const collection = Company.createCollection({
        pagination: {
          size: 20,
          page: 1,
        },
        filters: filters,
      })

      collection.refresh().then(() => {
        this.companies = collection.list
        this.companySearchLoading = false
      })
    },
    async handleCompanySelectedNext() {
      trackHeapEvent('Investor Search - Select Company', {
        companyId: this.selectedCompany.id,
        companyName: this.selectedCompany.name,
        userId: this.$store.getters.user.id,
        userEmail: this.$store.getters.user.email,
      })
      TrackedEvent.api.create(this.$store.getters.user.id, events.INVESTOR_SEARCH_SELECT_COMPANY, {
        companyId: this.selectedCompany.id,
        companyName: this.selectedCompany.name,
      })
      await this.prepopulateTagsFromCompany()
      this.step = 2
    },
    async prepopulateTagsFromCompany() {
      const collection = CompanyIndustry.createCollection({
        pagination: {
          size: 999,
          page: 1,
        },
        filters: {
          companyId: this.selectedCompany.id,
        },
      })
      await collection.refresh()

      let preselectedTagIds = collection.list.map((companyIndustry) => companyIndustry.industryId)
      // ensure no duplicates
      preselectedTagIds = [...new Set(preselectedTagIds)]
      // set preselected tags
      // sort them by name
      const preselectedTags = this.allTags.list.filter((t) => preselectedTagIds.includes(t.id))
      preselectedTags.sort(sortTagsByName)
      this.selectedTags = preselectedTags
    },
    handleExcludedCompaniesChange() {
      const queryParams = { ...this.$route.query }

      if (this.excludedCompanies && this.excludedCompanies.length > 0) {
        const excludedCompanyIds = this.excludedCompanies.map((company) => company.id).join(',')
        queryParams.excludedCompanyIds = excludedCompanyIds
      } else {
        delete queryParams.excludedCompanyIds
      }

      this.$router
        .push({ query: queryParams })
        .catch((err) => console.error('Router push error:', err))
    },
    handleSkipCompanySelection() {
      trackHeapEvent('Investor Search - Skip Company Selection', {
        userId: this.$store.getters.user.id,
        userEmail: this.$store.getters.user.email,
      })
      TrackedEvent.api.create(this.$store.getters.user.id, events.INVESTOR_SEARCH_SKIP_TO_TAGS)
      this.selectedCompany = null
      this.companySearchLoading = false
      this.selectedTags = []
      this.step = 2
    },
    handleGenerateResults(asGoogleSpreadsheet = false) {
      this.$store.dispatch('setLoading', true)

      this.setQueryParams()
      const filters = this.getFiltersForSearchQuery()
      if (asGoogleSpreadsheet) filters.asGoogleSpreadsheet = true
      if (this.selectedInvestorIds.length)
        filters.exportSelectedInvestors = this.selectedInvestorIds.join(',')

      Company.api
        .searchInvestors({
          pagination: {
            size: this.maxNumResults,
            page: 1,
          },
          filters,
        })
        .then((response) => {
          // handle possibility of async results being queued
          if (response.status === 204) {
            this.asyncResultsGeneration = true
            this.step = 3

            trackHeapEvent('Investor Search - Generate Results Async', {
              userId: this.$store.getters.user.id,
              userEmail: this.$store.getters.user.email,
              industryNames: this.selectedTags.map((t) => t.name).join(','),
              industryIds: this.selectedTags.map((t) => t.id).join(','),
              excludedCompanyIds: this.excludedCompanies.map((t) => t.id).join(','),
              midas: this.midasFilter,
              leads: this.leadsRoundsFilter,
              coinvestor: this.coinvestsWithFrcFilter,
              seed: this.seedStageFilter,
              early: this.earlyStageFilter,
              late: this.lateStageFilter,
            })

            TrackedEvent.api.create(
              this.$store.getters.user.id,
              events.INVESTOR_SEARCH_ASYNC_PENDING_VIEW,
              {
                industry_names: this.selectedTags.map((t) => t.name).join(','),
                industry_ids: this.selectedTags.map((t) => t.id).join(','),
                excluded_invested_in_company_ids: this.excludedCompanies
                  .map((company) => company.id)
                  .join(','),
                midas: this.midasFilter,
                leads: this.leadsRoundsFilter,
                coinvestor: this.coinvestsWithFrcFilter,
                seed: this.seedStageFilter,
                early: this.earlyStageFilter,
                late: this.lateStageFilter,
                as_google_spreadsheet: asGoogleSpreadsheet,
              },
            )

            return
          }

          // handle possibility of export to Google sheets
          if (filters.asGoogleSpreadsheet) {
            const { spreadsheetUrl } = objectToCamelCase(response.data)
            const link = document.createElement('a')
            link.href = spreadsheetUrl
            link.target = '_blank'
            link.click()

            trackHeapEvent('Investor Search - Generate Results as Google Spreadsheet', {
              userId: this.$store.getters.user.id,
              userEmail: this.$store.getters.user.email,
              industryNames: this.selectedTags.map((t) => t.name).join(','),
              industryIds: this.selectedTags.map((t) => t.id).join(','),
              excludedCompanyIds: this.excludedCompanies.map((t) => t.id).join(','),
              midas: this.midasFilter,
              leads: this.leadsRoundsFilter,
              coinvestor: this.coinvestsWithFrcFilter,
              seed: this.seedStageFilter,
              early: this.earlyStageFilter,
              late: this.lateStageFilter,
            })
            TrackedEvent.api.create(this.$store.getters.user.id, events.INVESTOR_SEARCH_EXPORT, {
              industry_names: this.selectedTags.map((t) => t.name).join(','),
              industry_ids: this.selectedTags.map((t) => t.id).join(','),
              excluded_invested_in_company_ids: this.excludedCompanies.map((t) => t.id).join(','),
              midas: this.midasFilter,
              leads: this.leadsRoundsFilter,
              coinvestor: this.coinvestsWithFrcFilter,
              seed: this.seedStageFilter,
              early: this.earlyStageFilter,
              late: this.lateStageFilter,
              as_google_spreadsheet: asGoogleSpreadsheet,
            })

            return
          }

          // handle possibility of actual results
          this.results = [...response.data.results.map(objectToCamelCase)]
          this.step = 3

          trackHeapEvent('Investor Search - Generate Results', {
            userId: this.$store.getters.user.id,
            userEmail: this.$store.getters.user.email,
            industryNames: this.selectedTags.map((t) => t.name).join(','),
            industryIds: this.selectedTags.map((t) => t.id).join(','),
            excludedCompanyIds: this.excludedCompanies.map((t) => t.id).join(','),
            midas: this.midasFilter,
            leads: this.leadsRoundsFilter,
            coinvestor: this.coinvestsWithFrcFilter,
            seed: this.seedStageFilter,
            early: this.earlyStageFilter,
            late: this.lateStageFilter,
          })
          TrackedEvent.api.create(
            this.$store.getters.user.id,
            events.INVESTOR_SEARCH_RESULTS_VIEW,
            {
              industry_names: this.selectedTags.map((t) => t.name).join(','),
              industry_ids: this.selectedTags.map((t) => t.id).join(','),
              excluded_invested_in_company_ids: this.excludedCompanies.map((t) => t.id).join(','),
              midas: this.midasFilter,
              leads: this.leadsRoundsFilter,
              coinvestor: this.coinvestsWithFrcFilter,
              seed: this.seedStageFilter,
              early: this.earlyStageFilter,
              late: this.lateStageFilter,
              as_google_spreadsheet: asGoogleSpreadsheet,
            },
          )
        })
        .finally(() => {
          // ensure cleared selectios state
          this.selectedInvestorIds = []
          // ensure the table re-renders
          this.lastFetchedResults = new Date().toISOString()
          // remove global loading UI
          this.$store.dispatch('setLoading', false)
        })
    },
    getFiltersForSearchQuery() {
      const filters = {
        recordTypeId: process.env.VUE_APP_INVESTOR_COMPANY_RECORD_TYPE_ID,
        investsInAnyOfIndustries: this.selectedTags.map((t) => t.id).join(','),
        excludedCompanyIds: this.excludedCompanies.map((t) => t.id).join(','),
      }

      if (this.midasFilter) {
        filters.wasOnMidasList = true
      }

      if (this.leadsRoundsFilter) {
        filters.leadsRounds = true
      }

      if (this.coinvestsWithFrcFilter) {
        filters.coinvestsWithFrc = true
      }

      const investsInAnyOfStages = []
      if (this.seedStageFilter) {
        investsInAnyOfStages.push('seed')
      }
      if (this.earlyStageFilter) {
        investsInAnyOfStages.push('early')
      }
      if (this.lateStageFilter) {
        investsInAnyOfStages.push('late')
      }
      if (investsInAnyOfStages.length) {
        filters.investsInAnyOfStages = investsInAnyOfStages.join(',')
      }

      return filters
    },
    setQueryParams() {
      const params = { ...this.$route.query }

      if (this.selectedTags.length) {
        params.industries = this.selectedTags.map((t) => t.id)
      }

      if (this.midasFilter) {
        params.midas = true
      }
      if (this.leadsRoundsFilter) {
        params.leads = true
      }
      if (this.coinvestsWithFrcFilter) {
        params.coinvestor = true
      }
      if (this.seedStageFilter) {
        params.seed = true
      }
      if (this.earlyStageFilter) {
        params.early = true
      }
      if (this.lateStageFilter) {
        params.late = true
      }
      params.pageSize = this.maxNumResults

      this.$router.push({
        name: this.$route.name,
        query: params,
      })
    },
    resetFilters() {
      this.midasFilter = false
      this.leadsRoundsFilter = false
      this.coinvestsWithFrcFilter = false
      this.seedStageFilter = false
      this.earlyStageFilter = false
      this.lateStageFilter = false
      this.maxNumResults = MAX_NUM_RESULTS_OPTIONS[0]
    },
    handleTagSelection() {
      this.resetFilters()
      this.handleGenerateResults()
    },
    handleShowDealInvestors(investments, hasMultipleIndustries, industryName) {
      this.dealInvestors = investments.map((di) => new DealInvestor(objectToCamelCase(di)))
      this.hasMultipleIndustries = hasMultipleIndustries
      this.isInvestmentsModalVisible = true
      this.industryName = industryName
    },
    handleShowGPs(investments) {
      this.dealInvestors = investments.map((di) => new DealInvestor(objectToCamelCase(di)))
      this.isGPsModalVisible = true
    },
    trackFilters() {
      trackHeapEvent('Investor Search - Select Filters', {
        userId: this.$store.getters.user.id,
        userEmail: this.$store.getters.user.email,
        midas: this.midasFilter,
        leads: this.leadsRoundsFilter,
        coinvestor: this.coinvestsWithFrcFilter,
        seed: this.seedStageFilter,
        early: this.earlyStageFilter,
        late: this.lateStageFilter,
      })
      TrackedEvent.api.create(this.$store.getters.user.id, events.INVESTOR_SEARCH_SELECT_FILTER, {
        midas: this.midasFilter,
        leads: this.leadsRoundsFilter,
        coinvestor: this.coinvestsWithFrcFilter,
        seed: this.seedStageFilter,
        early: this.earlyStageFilter,
        late: this.lateStageFilter,
      })
    },
    handleNewSearch() {
      TrackedEvent.api.create(
        this.$store.getters.user.id,
        events.INVESTOR_SEARCH_ASYNC_PENDING_NEW_SEARCH,
      )
      trackHeapEvent('Investor Search - Async Pending New Search', {
        userId: this.$store.getters.user.id,
        userEmail: this.$store.getters.user.email,
        industryNames: this.selectedTags.map((t) => t.name).join(','),
        industryIds: this.selectedTags.map((t) => t.id).join(','),
        midas: this.midasFilter,
        leads: this.leadsRoundsFilter,
        coinvestor: this.coinvestsWithFrcFilter,
        seed: this.seedStageFilter,
        early: this.earlyStageFilter,
        late: this.lateStageFilter,
      })

      // Navigate to the same route without any query parameters
      this.$router.push({ path: this.$route.path }).then(() => window.location.reload())
    },
  },
  computed: {
    debouncedSearchCompanies() {
      return debounce(this._searchCompanies, 760, { trailing: true })
    },
    exportButton() {
      if (this.selectedInvestorIds.length === 0) {
        return 'View All In Google Sheets'
      } else {
        return 'View ' + this.selectedInvestorIds.length + ' In Google Sheets'
      }
    },
  },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style>
.investor-info {
  margin-bottom: 10px;
}

.investor-info p {
  margin: 0;
}
@media (max-width: 640px) {
  .inline-flex {
    display: block;
    width: 100%;
    overflow-x: auto;
  }
}
</style>
